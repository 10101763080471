import axios from 'axios';

export function upload(data) {
  const axiosOptions = {
    responseType: 'arraybuffer',
    headers: {
      'Content-Type': 'application/json'
    }
  };

  return axios
    .post('/upload', data, axiosOptions)
    .then((response) => response)
    .catch((error) => {
      if (error.response) {
        return { error: true, errorMsg: '' };
      }
    });
}

export function getSlide() {
  return axios
    .get('/slide-count')
    .then((response) => response)
    .catch((error) => {
      console.log(error.response);
    });
}
