import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import PPTXDropzone from './components/PPTXDropzone';

export default function App() {
  return (
    <Router>
      <div>
        <Switch>
          <Route exact path="/">
            <PPTXDropzone />
          </Route>
        </Switch>
      </div>
    </Router>
  );
}
