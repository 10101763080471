import React, { Component } from 'react';
import Dropzone from 'react-dropzone';
import { config } from '../../config';
import { upload, getSlide } from '../../api';
import UploadingGif from '../../assets/images/Updating.gif';

export default class PPTXDropzone extends Component {
  constructor(props) {
    super(props);

    this.handleUploadFile = this.handleUploadFile.bind(this);
    this.connectWebSocket = this.connectWebSocket.bind(this);
    this.msgHandler = this.msgHandler.bind(this);
    this.updateSlideCount = this.updateSlideCount.bind(this);

    this.state = {
      loading: false,
      error: false,
      errorMsg: '',
      uploadedName: '',
      socketMessages: [],
      isDownloading: false,
      userID: '',
      slideCount: 0
    };
  }

  // register event listener for user id
  componentDidMount() {
    window.addEventListener('message', this.msgHandler, false);

    // Sample user id creation

    let sampleUserId = Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
    this.setState({
      userID: sampleUserId
    });

    this.updateSlideCount();
  }

  componentWillUnmount() {
    window.removeEventListener('message', this.msgHandler, false);
  }

  updateSlideCount() {
    getSlide()
      .then((response) => {
        if (response !== undefined) {
          this.setState({
            slideCount: response.data.slide_count
          });
        }
      })
      .catch((error) => {
        console.log(error.response);
      });
  }

  // Handle messages
  msgHandler(e) {
    // NOTE enable below to ensure that the messages are coming from trusted origin
    /*
    if (e.origin !== <orign url you want to get the message from>) {
      return;
    }
    */

    // Assuming the incoming data will be something like {type: 'user', userID: 'abc123'}
    // Check data type
    if (e.data.type === 'user') {
      let userID = e.data.userID;

      // update userID
      this.setState({
        userID: userID
      });
    }
  }

  connectWebSocket() {
    let ws = new WebSocket(config.SOCKET_URL + '?userID=' + this.state.userID);
    ws.onopen = () => {
      console.log('connected');
    };

    ws.onmessage = (evt) => {
      // Create new array with copy of previous messages
      let socketMessages = [...this.state.socketMessages];

      // check length
      if (socketMessages.length > 5) {
        // Remove old messages beginnning from index 1
        socketMessages.splice(1, 1);
      }
      socketMessages.push(evt.data);
      this.setState({
        socketMessages: socketMessages
      });
    };

    ws.onclose = () => {
      console.log('disconnected');
    };
  }

  handleUploadFile(file) {
    // Check for user id before processing
    if (this.state.userID === '') {
      this.setState({
        error: true,
        errorMsg: 'ERROR: User ID missing'
      });
      return;
    }

    // connect to web socket
    this.connectWebSocket();

    const data = new FormData();
    data.append('file', file[0]);
    data.append('userID', this.state.userID);

    this.setState({
      loading: true,
      socketMessages: [],
      uploadedName: file[0].name,
      isDownloading: false,
      error: false,
      errorMsg: ''
    });

    upload(data).then((response) => {
      if (response.error) {
        this.setState({
          loading: false,
          error: true,
          errorMsg: response.errorMsg,
          uploadedName: ''
        });
      } else {
        /* URL download
        const url = config.SERVER_URL + '/' + response.data.url;
        const link = document.createElement('a');
        link.href = url;
        document.body.appendChild(link);
        link.click();
        */

        const newName = response.headers['content-disposition'].split('"')[1];
        const url = URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', newName);
        document.body.appendChild(link);
        link.click();
        URL.revokeObjectURL(url); // revoke for the js garbage-collector to collect

        this.updateSlideCount();

        this.setState({
          loading: false,
          error: false,
          errorMsg: '',
          uploadedName: '',
          isDownloading: true
        });
      }
    });
  }

  render() {
    const accept = '.pptx';
    const maxSize = config.UPLOAD_MAX_SIZE * Math.pow(10, 6);

    return (
      <div className="pptx-dropzone">
        <div className="drop-container">
          <Dropzone
            maxSize={maxSize}
            accept={accept}
            onDrop={(acceptedFiles) => this.handleUploadFile(acceptedFiles)}
            disabled={this.state.loading}
          >
            {({ getRootProps, getInputProps }) => (
              <section>
                <div {...getRootProps({ className: 'dropzone' })}>
                  <input {...getInputProps()} />
                  {this.state.loading && !this.state.isDownloading && (
                    <div
                      className={
                        this.state.socketMessages.length > 0
                          ? 'processing'
                          : 'uploading'
                      }
                    >
                      <img
                        className="uploading-gif"
                        alt="Uploading..."
                        src={UploadingGif}
                      />
                      <div>
                        {this.state.socketMessages.length === 0 && (
                          <span>Uploading...</span>
                        )}
                      </div>
                    </div>
                  )}
                  {(!this.state.loading || this.state.isDownloading) && (
                    <div
                      className="drop-container-prompt"
                      style={
                        this.state.socketMessages.length > 0
                          ? {}
                          : { height: '100%' }
                      }
                    >
                      Drag and drop formatted HPE PPTX file here, or click to
                      select a file
                    </div>
                  )}
                  <div className="drop-container-text">
                    {this.state.error && (
                      <div className="error">{this.state.errorMsg}</div>
                    )}
                    <div className="progress-content">
                      {this.state.socketMessages.map((str, i) => {
                        return (
                          <div className="socket-msg" key={'socketMsg-' + i}>
                            {str}
                          </div>
                        );
                      })}
                      {this.state.isDownloading && (
                        <div>Downloading new file</div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="version">BETA BUILD 0.1.490</div>
              </section>
            )}
          </Dropzone>
        </div>
      </div>
    );
  }
}
