const prod = {
  SOCKET_URL: 'wss://pptx.aniden.com:3030',
  SERVER_URL: 'https://pptx.aniden.com',
  UPLOAD_MAX_SIZE: 25
};

const dev = {
  SOCKET_URL: 'ws://localhost:3030',
  SERVER_URL: 'http://localhost:5000',
  UPLOAD_MAX_SIZE: 2500
};

export const config = process.env.NODE_ENV === 'development' ? dev : prod;
